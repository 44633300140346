import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { AreaResponsibilityInfo } from "apina-frontend";
import { awaitForClose, SimplifyUsernamePipe } from "common";
import { NgClass } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";
import { AreaResponsibilityComponent, AreaResponsibilityComponentParams } from "../../dispatchers/area-responsibilities/area-responsibility.component";
import { MatDialog } from "@angular/material/dialog";
import { MainMenuService } from "../main-menu.service";

@Component({
    selector: 'app-main-menu-area-responsibilities',
    imports: [
        SimplifyUsernamePipe,
        NgClass,
        MatTooltip
    ],
    templateUrl: './main-menu-area-responsibilities.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuAreaResponsibilitiesComponent {

    private readonly matDialog = inject(MatDialog);
    private readonly mainMenuService = inject(MainMenuService);

    responsibilities = input.required<ReadonlyArray<AreaResponsibilityInfo>>();
    mayEdit = input(false);

    async changeResponsible(event: Event, responsibility: AreaResponsibilityInfo): Promise<void> {
        event.preventDefault();
        if (!this.mayEdit()) return;

        const dialog = this.matDialog.open<AreaResponsibilityComponent, AreaResponsibilityComponentParams>(AreaResponsibilityComponent, {
            data: {responsibility}
        });

        if (await awaitForClose(dialog))
            this.mainMenuService.reload();
    }
}
