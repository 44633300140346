import { ChangeDetectionStrategy, Component, computed, inject, signal, Signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { AreaResponsibilitiesEndpoint, AreaResponsibilityInfo } from "apina-frontend";
import { controlValuesSignal, ErrorService, PilotageAreaId, SimplifyUsernamePipe, SpinnerButtonComponent } from "common";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";

@Component({
    templateUrl: './area-responsibility.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        ReactiveFormsModule,
        SimplifyUsernamePipe,
        SpinnerButtonComponent,
    ]
})
export class AreaResponsibilityComponent {

    private readonly areaResponsibilitiesEndpoint = inject(AreaResponsibilitiesEndpoint);
    private readonly errorService = inject(ErrorService);
    readonly params = inject<AreaResponsibilityComponentParams>(MAT_DIALOG_DATA);
    private readonly dialogRef = inject(MatDialogRef<AreaResponsibilityComponent>);

    readonly areas = this.params.responsibility.areas.map(it => ({
        id: it.id,
        name: it.name,
        formControl: new FormControl(true, {nonNullable: true})
    }));

    readonly saving = signal(false);
    readonly disabled: Signal<boolean>;

    constructor() {
        const selections = this.areas.map(it => controlValuesSignal(it.formControl));

        this.disabled = computed(() => selections.every(it => !it()));
    }

    private get selectedAreas(): PilotageAreaId[] {
        return this.areas.filter(it => it.formControl.value).map(it => it.id);
    }

    async takeResponsibility(): Promise<void> {
        try {
            this.saving.set(true);
            await this.areaResponsibilitiesEndpoint.takeResponsibility(this.selectedAreas);
            this.dialogRef.close(true);
        } catch (e) {
            this.saving.set(false);
            return this.errorService.showUpdateError(e);
        }
    }

    async releaseResponsibility(): Promise<void> {
        try {
            this.saving.set(true);
            await this.areaResponsibilitiesEndpoint.releaseResponsibility(this.selectedAreas);
            this.dialogRef.close(true);
        } catch (e) {
            this.saving.set(false);
            return this.errorService.showUpdateError(e);
        }
    }
}

export interface AreaResponsibilityComponentParams {
    responsibility: AreaResponsibilityInfo;
}
