import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { FeedbackEndpoint } from "apina-frontend";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

@Component({
    templateUrl: './feedback.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButtonModule,
        MatDialogModule,
        ReactiveFormsModule
    ]
})
export class FeedbackComponent {

    private readonly feedbackEndpoint = inject(FeedbackEndpoint);
    private readonly dialogRef = inject(MatDialogRef<FeedbackComponent>);

    readonly form = new FormGroup({
        message: new FormControl("", {nonNullable: true, validators: Validators.required})
    });

    readonly saving = signal(false);
    readonly error = signal(false);

    async send(): Promise<void> {
        try {
            this.error.set(false);
            this.saving.set(true);
            await this.feedbackEndpoint.sendFeedback({message: this.form.controls.message.value});
            this.dialogRef.close();
        } catch (e) {
            console.error(e);
            this.saving.set(false);
            this.error.set(true);
        }
    }
}
