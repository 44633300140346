import { LoginResult, OAuthHandler, storeSessionToken } from "common";
import { LoginEndpoint, OAuthLoginSettings } from "apina-frontend";
import { AuthenticationResult } from "@azure/msal-browser";
import { inject, Injectable } from "@angular/core";
import { FrontendTranslations } from "../l10n/frontend.translations";

@Injectable({providedIn: "root"})
export class FrontendOAuthHandler implements OAuthHandler {

    private readonly loginEndpoint = inject(LoginEndpoint);
    private readonly frontendTranslateService = inject(FrontendTranslations);

    getLoginSettings(): Promise<OAuthLoginSettings> {
        return this.loginEndpoint.getLoginSettings();
    }

    async loginInternally(authenticationResult: AuthenticationResult): Promise<LoginResult> {
        const result = await this.loginEndpoint.loginWithAzureToken(authenticationResult.accessToken);
        storeSessionToken(result.jwtToken);
        this.frontendTranslateService.useAndStoreLanguage(result.language);
        return {type: "ok"};
    }

    async isLoggedIn(): Promise<boolean> {
        try {
            const status = await this.loginEndpoint.getLoginStatus();
            return status.loggedIn;
        } catch (e) {
            return false;
        }
    }
}
