import { inject, Injectable, Provider } from "@angular/core";
import { defer, Observable, timer } from "rxjs";
import { catchError, map, shareReplay, switchMap } from "rxjs/operators";
import { CameraInfo, MapDataProvider, PilotageInfo, PilotBoardingAreaInfo, PointOfInterestInfo, provideMapData, StationFenceInfo } from "maps";
import { CurrentAisData, MapEndpoint } from "apina-frontend";
import { Instant } from "@js-joda/core";
import { buildAuthenticatedUrl } from "common";

@Injectable()
class PilotwebMapDataProvider extends MapDataProvider {

    private readonly mapEndpoint = inject(MapEndpoint);
    readonly pilotages$: Observable<PilotageInfo[]> = timer(0, 60_000).pipe(
        switchMap(() => defer(() => this.mapEndpoint.getTrafficInfo()).pipe(
            map(pilotages => pilotages.map(it => ({
                state: it.state,
                inVesselNoticeState: it.inVesselNoticeState,
                vesselName: it.vessel.name,
                vesselMmsi: it.vessel.mmsi,
                startCode: it.routeStart.code,
                endCode: it.routeEnd.code,
                startTime: it.startTime,
                endTime: it.endTime
            }))),
            catchError(e => {
                console.log("error", e);
                return [];
            }))));

    readonly cameras$: Observable<CameraInfo[]> = timer(0, 60_000).pipe(
        switchMap(() => defer(() => this.mapEndpoint.findCameras()).pipe(
            catchError(e => {
                console.log("error", e);
                return [];
            }))),
        shareReplay(1));

    cameraUrl(cameraId: string, lastUpdated: Instant | null): string {
        // Add timestamp parameter to URL to force browser reload. Server ignores the parameter
        const params = (lastUpdated != null) ? {t: lastUpdated} : {};
        return buildAuthenticatedUrl(`/api/map/cameras/${cameraId}.jpg`,params);
    }

    findStationFences(): Promise<StationFenceInfo[]> {
        return this.mapEndpoint.findStationFences();
    }

    getCurrentAisData(): Promise<CurrentAisData> {
        return this.mapEndpoint.getCurrentAisData();
    }

    findPointsOfInterest(): Promise<PointOfInterestInfo[]> {
        return this.mapEndpoint.findPointsOfInterest();
    }

    findPilotBoardingAreas(): Promise<PilotBoardingAreaInfo[]> {
        return this.mapEndpoint.getPilotBoardingAreas();
    }
}

export function provideFrontendMapData(): Provider {
    return provideMapData(PilotwebMapDataProvider);
}
